import {TranslateService} from '../_services/translate.service';
import {forkJoin, map, mergeMap, of, tap} from 'rxjs';
import {getBrowserLocales} from '@app/shared/_helpers/i18n';
import {availableLanguages, fallBackLanguage} from "@config/config";
import {User} from "../_interfaces/user";
import {MockService} from "../_services/mock.service";
import {EmailchefService} from "@app/shared/_services/emailchef.service";
import {environment} from "@environments/environment";
import {inject} from "@angular/core";
import {FlowsBuilderService} from "@app/features/editor/builder.service";
import {getWhiteLabelResource, getWhiteLabelResourcePrefix} from "@app/shared/_helpers/wl";

export function setupUserConfig() {

  const translateService = inject(TranslateService);
  const emailchefService = inject(EmailchefService);
  const mockService = inject(MockService);
  const flowBuilderService = inject(FlowsBuilderService);

  return () => {
    return new Promise((resolve, reject) => {

      const supportedLanguages = availableLanguages.map(language => language.id);
      let navigatorLanguage = getBrowserLocales({languageCodeOnly: true})[0] || fallBackLanguage;

      if (!~supportedLanguages.indexOf(navigatorLanguage)) {
        navigatorLanguage = fallBackLanguage;
      }

      const userConfig$ = of<User.Config>({
        config: {
          fallback_language: fallBackLanguage,
          supported_languages: supportedLanguages,
          current_language: navigatorLanguage,
          timezone: '+00:00'
        },
        translations: {
          [fallBackLanguage]: mockService.getData('i18n/en'),
          ...(navigatorLanguage !== fallBackLanguage) && {[navigatorLanguage]: mockService.getData('i18n/' + navigatorLanguage)}
        }
      }).pipe(
        tap(appConfig => translateService.reloadByConfig(appConfig)),
        mergeMap(
          config => emailchefService.getAccountInfo()
            .pipe(
              map<any, User.Config>(res => {
                if (res.data.lang) {
                  config = {
                    config: {
                      ...config.config,
                      current_language: res.data.lang
                    },
                    translations: {
                      ...config.translations,
                      [res.data.lang]: mockService.getData(`i18n/${res.data.lang}`)
                    }
                  }
                }
                return config
              })
            )
        )
      );

      const agencyConfig$ = emailchefService
        .getAppConfig();

      return forkJoin([
        userConfig$,
        agencyConfig$
      ]).subscribe({
        next: ([appConfig, agencyConfig]) => {

          if (agencyConfig) {

            const whiteLabelResourcePrefix = getWhiteLabelResourcePrefix(
              agencyConfig
            );

            flowBuilderService.agencyConfig.update(
              _agencyConfig => ({
                ..._agencyConfig,
                name: agencyConfig.app_domain_name,
                logo: agencyConfig.app_domain_logo_editors,
                favicon: {
                  icon_default: getWhiteLabelResource(
                    "/img/favicons/favicon.ico",
                    whiteLabelResourcePrefix
                  ),
                  icon_57x57: getWhiteLabelResource(
                    "/img/favicons/apple-touch-icon-iphone.png",
                    whiteLabelResourcePrefix
                  ),
                  icon_72x72: getWhiteLabelResource(
                    "/img/favicons/apple-touch-icon-ipad.png",
                    whiteLabelResourcePrefix
                  ),
                  icon_114x114: getWhiteLabelResource(
                    "/img/favicons/apple-touch-icon-iphone-retina.png",
                    whiteLabelResourcePrefix
                  ),
                  icon_144x144: getWhiteLabelResource(
                    "/img/favicons/apple-touch-icon-ipad-retina.png",
                    whiteLabelResourcePrefix
                  ),
                  icon_192x192: getWhiteLabelResource(
                    "/img/favicons/favicon.png",
                    whiteLabelResourcePrefix
                  )
                }
              })
            );

          }

          translateService.reloadByConfig(appConfig);
          resolve(appConfig);
        },
        error: (error) => {
          reject(error);
        }
      });
    });
  };
}
