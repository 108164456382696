import {InjectionToken} from "@angular/core";
import {environment} from "@environments/environment";

export const API_BASE_URL = new InjectionToken<string>('apiBaseUrl');
export const EMAILCHEF_ENDPOINT_URL = new InjectionToken<string>('emailchefEndpointUrl');

const builderFlowApiUrl = {
    'builder.flows.emc.squeezetablet.com' : 'https://api.flows.emc.squeezetablet.com/index.php'
};

const emailchefEndpointUrl = {
  'builder.flows.emc.squeezetablet.com' : 'https://emc.squeezetablet.com'
};

export function getBaseUrl(){
  if (environment.production){
    if (window.location.hostname in builderFlowApiUrl){
      return builderFlowApiUrl[window.location.hostname];
    }
  }
  return environment.apiUrl;
}

export function getEmailchefEndpointUrl(){
  if (environment.production){
    if (window.location.hostname in emailchefEndpointUrl){
      return emailchefEndpointUrl[window.location.hostname];
    }
  }
  return environment.emailchefEndpointUrl;
}

