import {RouterModule, Routes} from '@angular/router';
import {FlowsBuilderService} from "@app/features/editor/builder.service";
import {computed, inject} from "@angular/core";


const appRoutes: Routes = [
  {path: '', loadChildren: () => import('./features/gallery/gallery.routes').then(m => m.galleryRoutes)},
  {
    path: 'editor',
    loadChildren: () => import('./features/editor/builder.routes').then(m => m.builderRoutes),
    /*title: () => {
      const agencyConfig = inject(FlowsBuilderService).agencyConfig();
      const name = computed(() => agencyConfig.name);
      return name();
    }*/
  },
  {path: '**', redirectTo: ''}
];

export const routing = RouterModule.forRoot(appRoutes, {});
