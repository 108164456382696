import {APP_INITIALIZER, enableProdMode, importProvidersFrom, LOCALE_ID} from '@angular/core';
import {environment} from './environments/environment';

import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeIt from '@angular/common/locales/it';
import localeFr from '@angular/common/locales/fr';
import localePt from '@angular/common/locales/pt';
import localeCs from '@angular/common/locales/cs';
import localeHr from '@angular/common/locales/hr';

import {registerLocaleData} from "@angular/common";
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {bootstrapApplication} from "@angular/platform-browser";
import {AppComponent} from "@app/app.component";
import {provideAnimations} from "@angular/platform-browser/animations";
import {setupUserConfig} from "@app/shared/_factories/config.factory";
import {TranslateService} from "@app/shared/_services/translate.service";
import {EmailchefService} from "@app/shared/_services/emailchef.service";
import {MockService} from "@app/shared/_services/mock.service";
import {AuthorizationInterceptor} from "@app/shared/_helpers/authorization.interceptor";
import {routing} from "@app/app.routing";
import {LocalizedDatePipe} from "@app/shared/_pipes/local.date.pipe";
import {MessageService} from "primeng/api";
import {
  API_BASE_URL,
  EMAILCHEF_ENDPOINT_URL,
  getBaseUrl,
  getEmailchefEndpointUrl
} from "@app/shared/_factories/apibaseurl.factory";

if (environment.production) {
  enableProdMode();
}


registerLocaleData(localeDe);
registerLocaleData(localeEs);
registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localePt);
registerLocaleData(localeCs);
registerLocaleData(localeHr);

bootstrapApplication(AppComponent, {
  providers: [
    LocalizedDatePipe,
    MessageService,
    importProvidersFrom(
      routing
    ),
    provideAnimations(),
    provideHttpClient(withInterceptors(
      [
        AuthorizationInterceptor
      ]
    )),
    {
      provide: APP_INITIALIZER,
      useFactory: setupUserConfig,
      multi: true
    },
    {
      provide: API_BASE_URL,
      useFactory: getBaseUrl
    },
    {
      provide: EMAILCHEF_ENDPOINT_URL,
      useFactory: getEmailchefEndpointUrl
    },
    {
      provide: LOCALE_ID,
      useFactory: (translateService: TranslateService) => translateService.getCurrentLanguage(),
      deps: [TranslateService]
    }
  ]
})
  .catch(err => console.error(err));
